import Base from './base';

class Resource extends Base {
  constructor(path) {
    super();
    this.getAll = (query) => this.getRequest(path, query);
    this.getById = (id) => this.getRequest(`${path}/${id}`);
    this.create = (data) => this.postRequest(path, data);
    this.update = (data, id) => this.putRequest(`${path}` + (id ? `/${id}` : ''), data);
    this.sort = (data) => this.putRequest(`${path}/sort`, data);
    this.delete = (id) => this.deleteRequest(`${path}/${id}`);
  }
}

export default Resource;
