import Base from './base';

class Main extends Base {
  login = (data) => this.postRequest('/auth/login', data);
  logOff = () => this.getRequest('/auth/logout');
  getUser = (query) => this.getRequest('/auth/user-profile', query);
  updatePassword = (data) => this.putRequest('/auth/change-password', data);
  uploadFile = (file, path) =>
    this.putFileRequest(`/admin/file/upload`, file, { name: file.name, path });
}

export default new Main();
