import React, { useCallback, useEffect } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';

import { useText, useGetData, usePutData, usePostData } from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import { List, Lang, Save, Gallery } from '../../../components';
import { useParams } from 'react-router';
import fileUpload from 'utils/file-uploader';

const initForm = {
  title_en: "",
  title_hy: "",
  text_ru: "",
  text_en: "",
  text_hy: "",
  text_ru: "",
  image: null
};

const Form = () => {
  const [data, loading, refresh] = useGetData(Api.exportInfo.getAll);

  const updateCallback = usePutData(Api.exportInfo.update, refresh);
console.log(data);
  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (!text.title_en || !text.title_ru || !text.title_hy ) {
        return setErrorMsg('title required in all languages');
      }
      
      text.image = text.file
        ? await fileUpload(text.file[0], 'export-image')
        : text.image;

      updateCallback(text);
    },
    [text, updateCallback]
    );
    
  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label="Section title" required/>
          <TextField {...input(`text_${lang}`)} label="Section text" multiline />
          <Gallery
                single
                label="Image"
                value={text.image}
                onChange={(img) => setText((st) => ({ ...st, image: img }))}
                setNewFile={(file) => setText((st) => ({ ...st, file }))}
              />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
