import React, { memo } from 'react';
import CardDrag from './drag_card';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { Card, IconButton } from '@material-ui/core';
import { imgUrl } from '../service';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexWrap: 'wrap'
  },
  box: {
    width: 'max-content'
  },
  img: {
    cursor: 'move',
    height: 200,
    width: 200,
    objectFit: 'cover'
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 99
  }
}));

const Images = ({ images, setImages }) => {
  const classes = useStyles();

  const moveCard = (dragIndex, hoverIndex) => {
    setImages(
      update(images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, images[dragIndex]]
        ]
      })
    );
  };

  if (images && !Array.isArray(images)) {
    return (
      <Card
        className={
          'card card-box px-2 py-2 m-2 font-weight-bold ' + classes.box
        }>
        {console.log(images.includes('pdf'))}

        {
          images.includes('pdf')
          ? <PictureAsPdf style={{width: '200px', height: '200px'}} />
          : <img className={classes.img} src={imgUrl + images} />
        }


        <IconButton onClick={() => setImages(null)} className={classes.icon}>
          <ClearIcon />
        </IconButton>
      </Card>
    );
  }

  return (
    <DndProvider backend={Backend}>
      <div className={'col-md-6 my-4 p-0 mx-auto d-flex ' + classes.wrapper}>
        {images?.map((card, index) => (
          <CardDrag
            key={card}
            index={index}
            moveCard={moveCard}
            node={
              <>
                <img className={classes.img} src={imgUrl + card} />
                <IconButton
                  onClick={() =>
                    setImages(images.filter((e, i) => index !== i))
                  }
                  className={classes.icon}>
                  <ClearIcon />
                </IconButton>
              </>
            }
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default memo(Images);
