import { useState, useCallback, useEffect } from 'react';
import config from '../config';

export const useText = (initialText) => {
  const [text, setText] = useState(initialText);
  const languages = config.languages;

  const clear_string = (string) => {
    let newString = string;

    languages.forEach((lang) => {
      newString = newString.replace(`_${lang}`, '');
    });

    return newString.replace('_', ' ');
  }

  const textChange = useCallback((e) => {
    const { name, value } = e.target;
    setText((text) => ({ ...text, [name]: value }));
  }, []);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  return [
    text,
    setText,
    textChange,
    (field) => ({
      name: field,
      value: text[field],
      variant: 'outlined',
      className: 'my-2',
      label: clear_string(field),
      fullWidth: true,
      onChange: textChange
    })
  ];
};
