import Resource from './resource';
import config from '../config';
export const imgUrl = config.imgBaseUrl;
export { default as main } from './main';
export const mainSlider = new Resource('/admin/main-slider');
export const categories = new Resource('/admin/product-categories');
export const aboutUs = new Resource('/admin/about-us');
export const productsReq = new Resource('/admin/products');
export const contact_info = new Resource('/admin/contact/content');
export const contacts_phone = new Resource('/admin/contact/phones');
export const catalogData = new Resource('/admin/catalog');
export const exportInfo = new Resource('/admin/export/content');
export const locationsData = new Resource('/admin/export/locations');
export const confidential = new Resource('/admin/confidential');
export const seoData = new Resource('/admin/seo/content');
export const greenData = new Resource('/admin/going-green');
