import React, { memo } from 'react';

import {
  Card,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const ITEM_HEIGHT = 24;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 13
    }
  }
};

function CustomSelect({
  field,
  style,
  multiple,
  title,
  data,
  value,
  onChange,
  className,
  noCard,
  disabled
}) {
  const Sel = (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{title}</InputLabel>
      <Select
        className={className}
        disabled={!!disabled}
        multiple={!!multiple}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        label={title}
        MenuProps={MenuProps}
        style={style}
        renderValue={
          multiple
            ? (selected) => (
                <div>
                  {selected.map((id) => (
                    <Chip
                      className="bg-primary text-white mx-1"
                      key={id}
                      label={
                        data.find((e) => e.id === id)[field || 'title_en']
                      }
                    />
                  ))}
                </div>
              )
            : null
        }>
        {data.map((el) => (
          <MenuItem key={el.id} value={el.id}>
            {el[field || 'title_en']}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (noCard) {
    return Sel;
  }

  return (
    <Card className="shadow-xxl px-4 my-3 py-3">
      <Grid>{Sel}</Grid>
    </Card>
  );
}

export default memo(CustomSelect);
