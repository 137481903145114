import React, { useCallback } from 'react';
import { Container, Card, Box, TextField } from '@material-ui/core';
import { Lang, Save } from '../../../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../../hooks';
import useStore from '../../../context';
import * as Api from '../../../service/index';

const initForm = {
  location_en: '',
  location_hy: '',
  location_ru: '',
};

const Form = () => {
  const { id } = useParams();
  const [data, loading, refresh] = useGetData(
    id && Api.locationsData.getById,
    id
  );
  const postCallback = usePostData(Api.locationsData.create, refresh);
  const updateCallback = usePutData(Api.locationsData.update, refresh);
  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data?.data || initForm);


  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      id ? updateCallback(text, id) : postCallback(text);
    },
    [text, setErrorMsg, id, updateCallback, postCallback]
  );
  
  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
        <Lang />

        <div className="d-flex align-items-center">
            <div className="w-100">
                {text && <TextField {...input(`location_${lang}`)} />}
            </div>
          </div>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
