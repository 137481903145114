import React, { memo } from 'react';
import { List, ListItem } from '@material-ui/core';

const Tab = ({ tabs, value, onChange }) => {
  return (
    <List
      component="div"
      className="my-3 nav-tabs justify-content-center nav-tabs-primary d-flex">
      {tabs.map((el) => (
        <ListItem
          button
          disableRipple
          selected={value === el}
          onClick={() => onChange(el)}>
          {el}
          <div className="divider" />
        </ListItem>
      ))}
    </List>
  );
};

export default memo(Tab);
