import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import Menu from '../../menu.json';
import crewLogo from '../../assets/crew.svg'
import sliderLogo from '../../assets/slider.svg'
import categoriesLogo from '../../assets/categories.svg'
import productLogo from '../../assets/product.svg'
import catalogLogo from '../../assets/catalog.svg'
import exportLogo from '../../assets/truck.svg'
import contactsLogo from '../../assets/contact.svg'
import greenLogo from '../../assets/green.svg'

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;
  const [menuOpen, setMenuOpen] = useState(null);

  return (
    <>
      <PerfectScrollbar>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            {Object.keys(Menu).map((el, index) => (
              <li>
                {Array.isArray(Menu[el]) ? (
                  <>
                    <a
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuOpen((st) => (st === index ? null : index));
                      }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                        {el === "Categories" ? <img src={categoriesLogo} alt="user" /> :
                          el === "Home" ? <img src={sliderLogo} alt="slider" /> :
                            el === "About Us" ? <img src={crewLogo} alt="About Us" /> :
                              el === "Products" ? <img src={productLogo} alt="product" /> :
                                el === "Contacts" ? <img src={contactsLogo} alt="contacts" /> :
                                  el === "Catalog" ? <img src={catalogLogo} alt="catalog" /> :
                                    el === "Export" ? <img src={exportLogo} alt="truck" /> :
                                      el === "Going Green" ? <img src={greenLogo} alt="green" /> :
                                        <VerifiedUserTwoToneIcon />}
                      </span>
                      <span
                        style={{
                          textTransform: 'capitalize'
                        }}
                        className="sidebar-item-label">
                        {el.replace('_', ' ')}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map((e) => (
                          <li>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {e.name.replace('-', ' ')}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                    <NavLink
                      to={Menu[el]}
                      onClick={() => {
                        setSidebarToggleMobile(false);
                        setMenuOpen(null);
                      }}>
                      <span className="sidebar-icon">
                        {el === "Categories" ? <img src={categoriesLogo} alt="user" /> :
                          el === "Home" ? <img src={sliderLogo} alt="slider" /> :
                            el === "About Us" ? <img src={crewLogo} alt="About Us" /> :
                              el === "Products" ? <img src={productLogo} alt="product" /> :
                                el === "Contacts" ? <img src={contactsLogo} alt="contacts" /> :
                                  el === "Catalog" ? <img src={catalogLogo} alt="catalog" /> :
                                    el === "Export" ? <img src={exportLogo} alt="truck" /> :
                                      el === "Going Green" ? <img src={greenLogo} alt="green" /> :
                                        <VerifiedUserTwoToneIcon />}
                      </span>
                      <span className="sidebar-item-label">
                        {el.replace('-', ' ')}
                      </span>
                    </NavLink>
                  )}
              </li>
            ))}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
