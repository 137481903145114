import React, { useCallback, } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';

import { useText, useGetData, usePutData } from '../../hooks';
import useStore from '../../context';

import * as Api from '../../service';
import { List, Lang, Save, Gallery } from '../../components';
import fileUpload from 'utils/file-uploader';

const initForm = {
  file: null
};

const Form = () => {
  const [data, loading, refresh] = useGetData(Api.catalogData.getAll);

  const updateCallback = usePutData(Api.catalogData.update, refresh);

  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      text.file = text.file[0]
        ? await await fileUpload(text.file[0], 'catalog-doc')
        : text.file;

        const data = {
          ...text,
          file: text.file
        }
        
        updateCallback(data);
    },
    [text, updateCallback]
    );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Gallery
            single
            label="File"
            value={text.file}
            onChange={(file) => setText((st) => ({ ...st, ...(file ? {file: file} : {}) }))}
            setNewFile={(file) => setText((st) => ({ ...st, ...(file ? {file: file} : {}) }))}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
