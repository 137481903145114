import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save } from '../../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import useStore from '../../context';
import FileUpload from '../../utils/file-uploader';

import * as Api from '../../service/index';

const initForm = {
  title_ru: '',
  title_hy: '',
  title_en: '',
  text_ru: '',
  text_hy: '',
  text_en: '',
  image: '',
};

const Form = () => {
  const { id } = useParams();
  const [data, loading, refresh] = useGetData(
    id && Api.mainSlider.getById,
    id
  );
  const postCallback = usePostData(Api.mainSlider.create, refresh);
  const updateCallback = usePutData(Api.mainSlider.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const [text, setText, textChange, input] = useText(data?.data || initForm);
  const [newFiles, setNewFiles] = useState(null);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (!text.title_en || !text.title_ru || !text.title_hy ) {
        return setErrorMsg('title required in all languages');
      }

      const link = newFiles
        ? await FileUpload(newFiles, 'main-slider')
        : text.image;

        const data = {
          ...text,
          image: link
        };
      
      id ? updateCallback(data, id) : postCallback(data);
    },
    [text, setErrorMsg, id, newFiles, updateCallback, postCallback]
  );

return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          
          <TextField {...input(`title_${lang}`)} required/>
          <TextField {...input(`text_${lang}`)} multiline/>
          

          <Gallery
            single
            title="Gallery"
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            onChange={(img) => setText((st) => ({ ...st, image: img }))}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
