

import React, { useCallback, } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';

import { useText, useGetData, usePutData } from '../../hooks';
import useStore from '../../context';

import * as Api from '../../service';
import { List, Lang, Save } from '../../components';
import { useParams } from 'react-router';

const initForm ={
    home_title_en: "",
    home_title_hy:"",
    home_title_ru:"",
    home_description_en:"",
    home_description_hy: "",
    home_description_ru:"",
    about_title_en:"",
    about_title_hy:"",
    about_title_ru:"",
    about_description_en: "",
    about_description_hy: "",
    about_description_ru: "",
    products_title_en: "",
    products_title_hy: "",
    products_title_ru: "",
    products_description_en:"",
    products_description_hy:"",
    products_description_ru:"",
    export_title_en:"",
    export_title_hy:"",
    export_title_ru:"",
    export_description_en:"",
    export_description_hy:"",
    export_description_ru:"",
    contact_title_en:"",
    contact_title_hy:"",
    contact_title_ru:"",
    contact_description_en:"",
    contact_description_hy:"",
    contact_description_ru:"",
    catalog_title_en:"",
    catalog_title_hy:"",
    catalog_title_ru:"",
    catalog_description_en:"",
    catalog_description_hy:"",
    catalog_description_ru:""
}

const Form = () => {
  const [data, loading, refresh] = useGetData(Api.seoData.getAll);

  const updateCallback = usePutData(Api.seoData.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const {slug} = useParams();

  const [text, setText, textChange, input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      updateCallback(text);
    },
    [text, updateCallback]
    );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`${slug}_title_${lang}`)} label="Title" required/>
          <TextField {...input(`${slug}_description_${lang}`)} label="Text" multiline />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
