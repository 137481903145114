import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save } from '../../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import useStore from '../../context';
import FileUpload from '../../utils/file-uploader';

import * as Api from '../../service/index';

const initForm = {
  text_ru: '',
  text_hy: '',
  text_en: '',
  about_us: '',
};

const Form = () => {
  const { id } = useParams();
  const [data, loading, refresh] = useGetData(
    id && Api.aboutUs.getById,
    id
  );
  const postCallback = usePostData(Api.aboutUs.create, refresh);
  const updateCallback = usePutData(Api.aboutUs.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const [text, setText, textChange, input] = useText(data?.data || initForm);
  const [newFiles, setNewFiles] = useState(null);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (!text.text_en || !text.text_ru || !text.text_hy ) {
        return setErrorMsg('text required in all languages');
      }

      const link = newFiles
        ? await FileUpload(newFiles, 'about-us')
        : text.image;

        const data = {
        ...text,
        image: link
      };
      
      id ? updateCallback(data, id) : postCallback(data);
    },
    [text, setErrorMsg, id, newFiles, updateCallback, postCallback]
  );

return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`text_${lang}`)} label="Text" required multiline />
          <Gallery
            single
            title="Gallery"
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            onChange={(img) => setText((st) => ({ ...st, image: img }))}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
