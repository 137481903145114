import { useCallback } from 'react';
import { useHistory } from 'react-router';
import useStore from '../context';

export const usePutData = (Api, refresh) => {
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data, id) => {
      try {
        await Api(data, id);
        if (typeof refresh === 'function') {
          refresh();
        }
        id && history.goBack();
        setSuccessMsg('updated');
      } catch (e) {
        console.log(e);
        setErrorMsg(e.message || 'there was an error');
      }
    },
    [Api, history, refresh, setErrorMsg, setSuccessMsg]
  );
};
