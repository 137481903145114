import React, { memo } from 'react';
import { List, ListItem } from '@material-ui/core';
import { FlagIcon } from 'react-flag-kit';
import useStore from '../context';
import config from '../config';
const iconCodes = {
  en: 'US',
  ru: 'RU',
  hy: "AM",
}

const Lang = () => {
  const { lang, setLang } = useStore();

  return (
    <List component="div" className="my-3 nav-tabs nav-tabs-primary d-flex">
      {config.languages.map(el =>
        <ListItem
          button
          disableRipple
          selected={lang === el}
          onClick={() => setLang(el)}>
          <FlagIcon code={iconCodes[el]} size={44} />
          <div className="divider" />
        </ListItem>
      )}
    </List>
  );
};

export default memo(Lang);
