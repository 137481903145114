

import React, { useCallback, } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { useText, useGetData, usePutData } from '../../hooks';
import useStore from '../../context';
import * as Api from '../../service';
import { Gallery, Lang, Save } from '../../components';
import { useParams } from 'react-router';
import fileUpload from 'utils/file-uploader';

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  text_en: "",
  text_ru: "",
  text_hy: "",
  url: "",
  images: []
}

const Form = () => {
  const [data, loading, refresh] = useGetData(Api.greenData.getAll);

  const updateCallback = usePutData(Api.greenData.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const {slug} = useParams();

  const [text, setText, textChange, input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      text.images = text.files
      ? await Promise.all(
            text.files.map( async (file) => await fileUpload(file, 'going-green'))
          )
      : text.images;
      const data = {
        ...text,
        images: text.images
      }
      
      updateCallback(data);
    },
    [text, updateCallback]
    );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label="Title" required/>
          <TextField {...input(`text_${lang}`)} label="Text" multiline />
          <TextField {...input("url")} label="url" />
          <Gallery
            label="Gallery"
            value={text.images}
            onChange={(imgs) => setText((st) => ({ ...st, images: imgs }))}
            setNewFile={(files) => setText((st) => ({ ...st, files: files }))}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
