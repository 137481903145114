import React, { useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';

import { useText, useGetData, usePutData } from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import { Lang, Save } from '../../../components';

const initForm = {
  title_en: '',
  title_hy: '',
  text_ru: '',
  text_en: '',
  text_hy: '',
  address_en: '',
  address_hy: '',
  address_ru: '',
  email: '',
  map: '',
  copyrightText_en: '',
  copyrightText_hy: '',
  copyrightText_ru: ''
};

const Form = () => {
  const [data, loading, refresh] = useGetData(Api.contact_info.getAll);

  const updateCallback = usePutData(Api.contact_info.update, refresh);

  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (!text.title_en || !text.title_ru || !text.title_hy) {
        return setErrorMsg('title required in all languages');
      }
      updateCallback(text);
    },
    [text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField
            {...input(`title_${lang}`)}
            label="Section title"
            required
          />
          <TextField
            {...input(`text_${lang}`)}
            label="Section text"
            multiline
          />
          <TextField {...input(`address_${lang}`)} label="Address" />
          <TextField {...input(`email`)} label="Email" type="email" required />
          <TextField {...input(`map`)} label="Map embedded code" />
          <TextField
            {...input(`copyrightText_${lang}`)}
            label="Copyright text"
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
