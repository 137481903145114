import React from 'react';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList, usePutData } from '../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../service';
import useStore from '../../context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const query = {
  limit: 10,
  sort: 'id',
  order: 'asc'
};

export default function AboutUs() {
  const { setRemove, lang } = useStore();
  const [items, total, refresh, setItems] = useGetList(
    Api.aboutUs.getAll,
    query
  );
  const orderCallback = usePutData(Api.aboutUs, refresh);

  const rows = items?.map((item) => (
    <>
      <td className="text-left">
        <div className="font-size-sm">
          {item[`text_${lang}`].length > 200 ? item[`text_${lang}`].slice(0, 200) + "..." : item[`text_${lang}`]}
        </div>
      </td>
      <td className="text-right">
        <Link to={`/about/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.aboutUs.delete(item.id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="danger" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        title="About Us"
        items={items}
        setItems={setItems}
        total={total}
        titles
        rows={rows}
        orderCallback={orderCallback}
        createLink="about/create"
      />
    </WrapperSeamless>
  );
}
