import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save } from '../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../hooks';
import useStore from '../context';

import * as Api from '../service/index';

const initForm = {
  terms_en: '',
  terms_it: '',
  data_processing_en: '',
  data_processing_it: '',
  cookie_policy_en: '',
  cookie_policy_it: '',
};

const Form = () => {
  const { page } = useParams();
  const [data, loading, refresh] = useGetData(Api.confidential.getAll);
  const updateCallback = usePutData(Api.confidential.update, refresh);
  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      updateCallback(text);
    },
    [text, setErrorMsg, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />

          {page === 'terms' && <TextField {...input(`terms_${lang}`)} multiline rows="15" />}
          {page === 'processing' && <TextField {...input(`data_processing_${lang}`)} multiline rows="15" />}
          {page === 'cookie' && <TextField {...input(`cookie_policy_${lang}`)} multiline rows="15" />}

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
