import React, { useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Lang, Save } from '../../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../hooks';
import useStore from '../../context';

import * as Api from '../../service/index';

const initForm = {
  title_ru: '',
  title_hy: '',
  title_en: '',
  order: ''
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(id && Api.categories.getById, id);

  const postCallback = usePostData(Api.categories.create, refresh);
  const updateCallback = usePutData(Api.categories.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const [text, , , input] = useText(data?.data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (!text.title_en || !text.title_ru || !text.title_hy) {
        return setErrorMsg('title required in all languages');
      }

      const updatedData = { ...text, order: text.order ?? 0 };

      id ? updateCallback(updatedData, id) : postCallback(updatedData);
    },
    [text, setErrorMsg, id, updateCallback, postCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} required />
          <TextField
            {...input(`order`)}
            type="number"
            inputProps={{ min: 0 }}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
