import axios from 'axios';
import config from '../../config';

export default async (file, path) => {
    try {
        const data = new FormData();
        data.append("file", file[0] || file)
        data.append("path", path);
        const result = await axios.post(`${config.apiBaseUrl}/admin/file/upload`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: sessionStorage.token ?? localStorage.token,
                }
        });
        return result.data;
    } catch(error) {
        console.log(error)
    }
}