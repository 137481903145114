import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save, Select } from '../../components';
import { useParams } from 'react-router-dom';
import {
  useText,
  useGetData,
  usePutData,
  usePostData,
  useGetList
} from '../../hooks';
import useStore from '../../context';
import * as Api from '../../service/index';
import NumberFormat from 'react-number-format';
import FileUpload from '../../utils/file-uploader';

const fields = ['title', 'description'];
const initForm = {
  title_en: '',
  title_ru: '',
  title_hy: '',
  description_en: '',
  description_ru: '',
  description_hy: '',
  image: '',
  capacity: '',
  rating: '',
  categories: [],
  order: 0
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(
    id && Api.productsReq.getById,
    id
  );

  const postCallback = usePostData(Api.productsReq.create, refresh);
  const updateCallback = usePutData(Api.productsReq.update, refresh);

  const { lang } = useStore();
  const [text, setText, , input] = useText(data?.data || initForm);
  const [newFiles, setNewFiles] = useState(null);
  const [categories] = useGetList(Api.categories.getAll);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const link = newFiles
        ? await FileUpload(newFiles, 'products-image')
        : text.image;

      const data = {
        ...text,
        order: text.order ?? 0,
        image: link
      };

      id ? updateCallback(data, id) : postCallback(data);
    },
    [text, id, newFiles, updateCallback, postCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          {fields.map((el, i) => (
            <TextField key={i} {...input(`${el}_${lang}`)} />
          ))}
          {categories && (
            <Select
              multiple
              field={`title_${lang}`}
              title="Select categories"
              value={text.categories || []}
              data={categories}
              onChange={(val) => setText((st) => ({ ...st, categories: val }))}
            />
          )}
          <div className="d-flex align-items-center">
            <div className="w-100">
              Rating
              <div className="MuiOutlinedInput-root custom-number-input">
                <NumberFormat
                  {...input(`rating`)}
                  decimalScale={2}
                  placeholder="Rating"
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (
                      formattedValue === '' ||
                      (floatValue <= 5 && floatValue >= 1)
                    );
                  }}
                  className="MuiInputBase-input MuiOutlinedInput-input"
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center mt-3">
            <div className="w-100">
              Capacity
              <div className="MuiOutlinedInput-root custom-number-input">
                <NumberFormat
                  {...input(`capacity`)}
                  decimalScale={2}
                  placeholder="Capacity"
                  className="MuiInputBase-input MuiOutlinedInput-input"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="w-100">
              Order
              <div className="MuiOutlinedInput-root custom-number-input">
                <NumberFormat
                  {...input(`order`)}
                  decimalScale={0}
                  placeholder="Order"
                  className="MuiInputBase-input MuiOutlinedInput-input"
                />
              </div>
            </div>
          </div>
          <Gallery
            single
            title="Gallery"
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            onChange={(img) => setText((st) => ({ ...st, image: img }))}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
