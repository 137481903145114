import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './pages/login';
import { LeftSidebar } from './layout-blueprints';
import useStore from './context';
import * as Api from './service';
import { SuspenseLoading } from 'components';
import {
  PasswordChangeForm,
  MainSlider,
  MainSliderForm,
  Categories,
  CategoriesForm,
  AboutUs,
  AboutUsForm,
  Products,
  ProductsForm,
  Confidential,
  CityAsHelp,
  CityAsLead,
  ContactsInfo,
  PhoneNumber,
  PhoneNumberForm,
  Catalog,
  ExportInfo,
  LocationsList,
  LocationsForm,
  SeoInfo,
  GoingGreen
} from './pages';

const Routes = () => {
  const [show, setShow] = useState(false);
  const { user, setUser } = useStore();

  useEffect(() => {
    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
      Api.main
        .getUser()
        .then((res) => {
          setUser(res);
          setShow(true);
        })
        .catch((e) => {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          setShow(true);
        });
    } else {
      setShow(true);
    }
  }, [setUser]);

  return (
    show && (
      <Suspense fallback={<SuspenseLoading />}>
        {!user ? (
          <Login />
        ) : (
          <LeftSidebar>
            <Switch>
              <Route
                exact
                path="/password-change"
                component={PasswordChangeForm}
              />
              <Route exact path="/main-slider/create/:id?" component={MainSliderForm} />
              <Route exact path="/main-slider" component={MainSlider} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/about/create/:id?" component={AboutUsForm} />
              <Route exact path="/confidential/:page" component={Confidential} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/products/create/:id?" component={ProductsForm} />
              <Route exact path="/categories" component={Categories} />
              <Route exact path="/categories/create/:id?" component={CategoriesForm} />
              <Route exact path="/contacts" component={ContactsInfo} />
              <Route exact path="/contacts/phone" component={PhoneNumber} />
              <Route exact path="/contacts/phone/create/:id?" component={PhoneNumberForm} />
              <Route exact path="/catalog" component={Catalog} />
              <Route exact path="/export" component={ExportInfo} />
              <Route exact path="/export/locations" component={LocationsList} />
              <Route exact path="/export/locations/create/:id?" component={LocationsForm} />
              <Route exact path={`/seo/:slug`} component={SeoInfo} />
              <Route exact path="/green" component={GoingGreen} />
            </Switch>
          </LeftSidebar>
        )}
      </Suspense>
    )
  );
};

export default Routes;
