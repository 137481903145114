import { useCallback } from 'react';
import useStore from '../context';
import { useHistory } from 'react-router-dom';

export const usePostData = (Api, refresh) => {
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data) => {
      try {
        await Api(data);
        refresh();
        setSuccessMsg('added');
        history.goBack();
      } catch (e) {
        console.log(e);
        setErrorMsg(e.message || 'there was an error');
      }
    },
    [Api, history, refresh, setErrorMsg, setSuccessMsg]
  );
};
